import React, { Suspense, useEffect, useState } from "react";
import Navbar from "./Components/Navbar";
import { Routes, Route } from "react-router-dom"
import SocialIcons from "./Components/SocialIcons";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "./Components/Footer";
import "./App.css"
import Loading from "./Components/Loading";
const NotFound = React.lazy(() => import("./Pages/NotFound"));
const Home = React.lazy(() => import('./Pages/Home'));
const About = React.lazy(() => import('./Pages/About'));
const Blogs = React.lazy(() => import('./Pages/Blogs'));
const ContactMe = React.lazy(() => import('./Pages/ContactMe'));
const ProjectPage = React.lazy(() => import("./Pages/ProjectPage"));
const Resume = React.lazy(() => import('./Pages/Resume'));


function App() {
  let [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false)
    }, 1500)
  }, [])
  return (
    <div className="app">
      {
        loading ? <div className="h-screen w-full flex justify-center items-center">
          <Loading></Loading>
        </div> :
          <Navbar>
            <SocialIcons></SocialIcons>
            <Routes>
              <Route path="/" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Home></Home>
                </Suspense>
              }></Route>
              <Route path="/*" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <NotFound></NotFound>
                </Suspense>
              }></Route>
              <Route path="/about" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <About></About>
                </Suspense>
              }></Route>
              <Route path="/projects" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <ProjectPage></ProjectPage>
                </Suspense>
              }></Route>
              <Route path="/resume" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Resume></Resume>
                </Suspense>
              }></Route>
              <Route path="/blogs" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <Blogs></Blogs>
                </Suspense>
              }></Route>
              <Route path="/contact" element={
                <Suspense fallback={<div>Loading...</div>}>
                  <ContactMe></ContactMe>
                </Suspense>
              }></Route>
            </Routes>
            <Footer></Footer>
            <ToastContainer></ToastContainer>
          </Navbar>
      }

    </div>
  );
}

export default App;
