import { primary } from 'daisyui/src/colors';
import React from 'react';
import { ThreeDots } from 'react-loader-spinner';

const Loading = () => {
    return (
        <div>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#fb4226"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
            />
        </div>
    );
};

export default Loading;