import React from 'react';
import './Footer.css'


const Footer = () => {
    const d = new Date();
    let year = d.getFullYear();
    return (
        <div>
            <footer className=" bg-neutral text-white py-5  w-full ">

                <p className='text-center'>Copyright © {year} - All Rights Reserved by <span className='text-primary'>Md Shahriur Ahmed</span></p>


            </footer>
        </div>
    );
};

export default Footer;