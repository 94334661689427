import React from 'react';
import "./SocialIcon.css"
import giticon from "../Assets/logo_and_icons/git-01.png"
import likedin from "../Assets/logo_and_icons/in-01.png"
import fb from "../Assets/logo_and_icons/fb-01.png"
import be from "../Assets/logo_and_icons/be-01.png"
import tw from "../Assets/logo_and_icons/tw-01.png"

const SocialIcons = () => {
    return (
        <div>
            <div className='social-bg bg-primary'>
                <a href="https://github.com/mdshahriurahmed" target="_blank" rel='noreferrer'> <img src={giticon} alt="" /></a>
                <a href="https://www.linkedin.com/in/md-shahriur-ahmed/" target="_blank" rel='noreferrer'><img src={likedin} alt="" /></a>
                {/* <a href="https://www.behance.net/mdshahriurahmed" target="_blank" rel='noreferrer'><img src={be} alt="" /></a> */}
                <a href="https://twitter.com/mdshahriurahmed" target="_blank" rel='noreferrer'><img src={tw} alt="" /></a>
                <a href="https://www.facebook.com/shahriurahmed/" target="_blank" rel='noreferrer'><img src={fb} alt="" /></a>

            </div>
        </div>
    );
};

export default SocialIcons;